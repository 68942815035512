<template>
  <div class='page'>
    <div class='content'>
      <div class='tree-box'>
        <div class='tree-title-box'>
          <div>
            <span>合伙人管理</span>
            <a-icon type="question-circle" />
          </div>
          <a-button type='link' style='padding:0;' @click='addPartner'>添加</a-button>
        </div>
        <a-spin :spinning='treeLoading' class='tree'>
          <a-tree :selectedKeys="[currentSelectData.partnerCode]" :expandedKeys="expandedKeys" v-if='treeData.length' :tree-data="treeData" @select='selectTree' @expand='expandTree' :replaceFields="{children:'children', title:'partnerName', key:'partnerCode' }">

            <template slot="custom" slot-scope="item">
              <a-popover trigger='hover' placement='right'>
                <template slot="content">
                  <a-button icon='edit' type='link' @click='editPartner(item)'></a-button>
                  <a-button icon='delete' type='link' @click='deletePartner(item)'></a-button>
                </template>
                <span>{{item.partnerName}}</span>
              </a-popover>
            </template>

          </a-tree>
          <div v-else style='padding-top:32px'>
            <a-empty></a-empty>
          </div>
        </a-spin>
      </div>
      <div class='content-box'>
        <a-spin :spinning='contentLoading'>
          <div v-if="currentSelectData.partnerId">
            <div class='content-title'>
              {{currentSelectData.partnerName}}
            </div>
            <div class='amount-box'>
              <div class='amount-box-item'>
                <div class='label'>累计分成（元）</div>
                <div class='value' style='color:red;'>{{currentSelectData.totalAmount||0}}</div>
              </div>
              <div class='amount-box-item'>
                <div class='label'>可提现分成（元）</div>
                <div class='value'>{{currentSelectData.waitSettleAmount||0}}</div>
              </div>
            </div>
            <div>
              <div class='line'>
                <div class='label'>联系人：</div>
                <div class='value'>{{currentSelectData.linkMan||'-'}}</div>
              </div>
              <div class='line'>
                <div class='label'>联系方式：</div>
                <div class='value'>{{currentSelectData.linkPhone||'-'}}</div>
              </div>
              <div class='line'>
                <div class='label'>联系地址：</div>
                <div class='value'>{{(currentSelectData.regionName + currentSelectData.address)||'-'}}</div>
              </div>
              <div class='line'>
                <div class='label'>分成比例：</div>
                <div class='value'>{{currentSelectData.brokerageRate||'-'}}%</div>
              </div>
              <div style='padding-left:32px' v-if='currentSelectData.partnerPcode !=="0"'>
                <a-button type='primary' style='margin-right: 32px' @click='getQrcode' :loading='qrcodeBtnLoading'>查看推广二维码</a-button>
                <a-button type='primary' v-clipboard:copy="" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">复制小程序链接</a-button>
              </div>
            </div>
          </div>
          <div v-else style='padding-top:32px'>
            <a-empty description='请先选择合伙人'></a-empty>
          </div>
        </a-spin>
      </div>
    </div>
    <a-modal v-model="showModal" :width="640" :title='isAdd?"添加合伙人":"编辑合伙人"' :confirm-loading='modalLoading' @ok='submit'>
      <a-spin :spinning='formLoading'>
        <a-form-model class='modal-form' ref="formRef" :model="formData" @submit.native.prevent :label-col="{span:5}" :wrapper-col="{span:19}" :rules="rules">
          <a-form-model-item label="等级" prop="partnerLevel">
            <a-radio-group v-model="formData.partnerLevel" :options="options" />
          </a-form-model-item>
          <a-form-model-item label="合伙人名称" prop="partnerName">
            <a-input v-model="formData.partnerName" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="所属上级合伙人" prop="partnerPcode" v-if="formData.partnerLevel === 2">
            <a-select v-model="formData.partnerPcode" placeholder="请选择">
              <a-select-option v-for='item in treeData' :value="item.partnerCode" :key='item.partnerCode'>{{item.partnerName}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="联系人" prop="linkMan">
            <a-input v-model="formData.linkMan" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系方式" prop="linkPhone">
            <a-input :maxLength="11" v-model="formData.linkPhone" placeholder="联系方式"></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系地址：" prop="regionCode">
            <a-cascader placeholder="请选择省市区" v-model="formData.regionCode" :options="regionOptions" @change="regionChange" />
          </a-form-model-item>
          <a-form-model-item label="详细地址：" prop="address">
            <a-input placeholder="请输入详细地址" allowClear v-model="formData.address" />
          </a-form-model-item>
          <a-form-model-item label="佣金比例" prop="brokerageRate">
            <a-input suffix='%' placeholder='请输入' style='width:160px' v-model="formData.brokerageRate"></a-input>
          </a-form-model-item>

        </a-form-model>
      </a-spin>
    </a-modal>
    <a-modal :width='400' v-model="showQrcodeImg" :footer="null">
      <base-img class="qrcode-img" :src="qrcodeUrl" />
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from '@vue/composition-api'
import { marketing, common } from '@/api'
import { phoneValidate } from '@/utils/validator'
export default {
  name: 'PagePartner',
  setup (props, { root }) {
    const checkRatio = (rule, value, callback) => {
      if (value) {
        const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
        if (!reg.test(value)) {
          return callback(new Error('整数或最多两位小数'))
        } else if (value > 100) {
          return callback(new Error('不能超过100'))
        } else if (value <= 0) {
          return callback(new Error('必须大于0'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const deepClone = (obj) => {
      let objClone = Array.isArray(obj) ? [] : {}
      if (obj && typeof obj === 'object') {
        for (let key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            // 判断obj子元素是否为对象，如果是，递归复制
            if (obj[key] && typeof obj[key] === 'object') {
              objClone[key] = deepClone(obj[key])
            } else {
              // 如果不是，简单复制
              objClone[key] = obj[key]
            }
          }
        }
      }
      return objClone
    }
    const state = reactive({
      virtualSharerId: '',
      treeLoading: false,
      showModal: false,
      modalLoading: false,
      treeData: [],
      formRef: null,
      contentLoading: false,
      isAdd: true,
      formLoading: false,
      currentSelectData: {},
      qrcodeBtnLoading: false,
      formData: {
        partnerLevel: 1,
        partnerPcode: undefined,
        regionCode: undefined,
        linkPhone: '',
        partnerName: '',
        brokerageRate: '',
        address: '',
      },
      expandedKeys: [],
      qrcodeUrl: '',
      showQrcodeImg: false,
      regionOptions: [],
      rules: {
        partnerLevel: [{ required: true, message: '请选择', trigger: 'change' }],
        partnerName: [
          { required: true, message: '请填写', trigger: 'change' },
          { max: 32, message: '请尝试简短一些的名称', trigger: 'change' },
        ],
        partnerPcode: [{ required: true, message: '请选择', trigger: 'change' }],
        linkPhone: [{ required: true, validator: phoneValidate, trigger: 'change' }],
        brokerageRate: [
          { required: true, message: '请选择', trigger: 'change' },
          { validator: checkRatio, trigger: 'change' },
        ],
      },
    })
    const options = [
      { label: '高级合伙人', value: 1 },
      { label: '合伙人', value: 2 },
    ]

    onMounted(() => {
      Promise.all([getRegionTree(), getPartnerTreeData()])
    })
    async function getRegionTree () {
      let { data, msg, code } = await common.getRegionTree()
      if (code === '00000') {
        state.regionOptions = handleTreeData(data)
      } else {
        root.$message.error(msg || '网络错误，请重试')
      }
    }
    function onCopySuccess () {
      root.$message.success('复制成功')
    }
    function onCopyError () {
      root.$message.error('复制失败')
    }
    function regionChange (e, selectedOptions) {
      if (selectedOptions) {
        state.formData.regionName =
          (selectedOptions[0].label || '') +
          (selectedOptions[1].label || '') +
          (selectedOptions[2].label || '')
      } else {
        state.formData.regionName = ''
      }
    }
    function handleTreeData (data) {
      if (!data || !data.length) return
      for (let item of data) {
        item.value = item.regionCode
        item.label = item.regionName
        item.children = handleTreeData(item.children)
      }
      return data
    }
    async function getPartnerTreeData (pcode = '') {
      state.treeLoading = true
      const { data, msg, code } = await marketing.getPartnerTreeData({ code: pcode })
      state.treeLoading = false
      if (code === '00000') {
        let newData = data.map((x) => {
          return {
            isLeaf: !x.nextFlag,
            ...x,
            scopedSlots: {
              title: 'custom',
            },
          }
        })
        if (pcode) {
          let index = state.treeData.findIndex((x) => x.partnerCode === pcode)
          root.$set(state.treeData[index], 'children', newData)
        } else {
          state.treeData = newData
        }
        state.treeData = [...state.treeData]
        if (state.treeData.length && state.treeData[0].partnerCode) {
          if (!state.currentSelectData.partnerCode) {
            state.currentSelectData.partnerCode = state.treeData[0].partnerCode
          }
          getPartnerDetail()
        }
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function addPartner () {
      state.isAdd = true
      state.showModal = true
      await root.$nextTick()
      state.formRef.resetFields()
      state.formData = deepClone({
        partnerLevel: 1,
        partnerPcode: undefined,
        regionCode: undefined,
        linkPhone: '',
        partnerName: '',
        brokerageRate: '',
        address: '',
      })
      state.formData.regionName = ''
      state.formData.regionCode = undefined
    }
    function submit () {
      state.formRef.validate(async (valid) => {
        if (valid) {
          let param = deepClone(state.formData)
          if (param.regionCode) {
            param.regionCode = state.formData.regionCode.join(',')
          }

          state.modalLoading = true
          const { code, msg, data } = await marketing.savePartner(param)
          state.modalLoading = false
          if (code === '00000') {
            root.$message.success('保存成功')
            state.showModal = false
            const { partnerPcode, partnerCode } = data

            state.currentSelectData.partnerCode = partnerCode
            if (partnerPcode === '0') {
              console.log(state.isAdd)
              if (state.isAdd) {
                state.treeData.push({
                  partnerCode: partnerCode,
                  partnerName: param.partnerName,
                  isLeaf: true,
                  scopedSlots: {
                    title: 'custom',
                  },
                })
                getPartnerDetail()
              } else {
                getPartnerTreeData()
              }
            } else {
              if (state.expandedKeys.indexOf(partnerPcode) === -1) {
                state.expandedKeys.push(partnerPcode)
              }
              state.treeData[ state.treeData.findIndex((x) => x.partnerCode === partnerPcode) ].isLeaf = false
              getPartnerTreeData(param.partnerPcode)
            }
          } else {
            root.$message.error(msg || '保存失败')
          }
        }
      })
    }
    async function selectTree (keys, e) {
      state.currentSelectData.partnerCode = e.node.dataRef.partnerCode
      state.contentLoading = true
      await getPartnerDetail(e.node.dataRef.partnerCode)
      if (e.node.dataRef.partnerPcode !== '0') {
        await getVirtualSharerId(e.node.dataRef.partnerId)
      }
      state.contentLoading = false
    }
    async function getVirtualSharerId (id) {
      console.log(id)
      const { data, msg, code } = await marketing.getVirtualSharerId({
        partnerId: id
      })
      if (code === '00000') {
        console.log(data)
        state.virtualSharerId = data
      }
    }
    async function getPartnerDetail (partnerCode = state.currentSelectData.partnerCode ? state.currentSelectData.partnerCode : '', key = 'currentSelectData') {
      const { data, msg, code } = await marketing.getPartnerDetail({
        code: partnerCode,
      })

      if (code === '00000') {
        if (key === 'formData' && data.regionCode) {
          data.regionCode = data.regionCode.split(',')
        }
        state[key] = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function getQrcode () {
      state.qrcodeBtnLoading = true
      const { data, msg, code } = await marketing.getPartnerQrcode({
        partnerId: state.currentSelectData.partnerId
      })
      state.qrcodeBtnLoading = false
      console.log(state.currentSelectData)
      if (code === '00000') {
        if (data) {
          state.qrcodeUrl = data
          state.showQrcodeImg = true
        }
      } else {
        root.$message.error(msg || '获取二维码失败')
      }
    }
    async function editPartner (item) {
      state.isAdd = false
      state.showModal = true
      state.formLoading = true
      await getPartnerDetail(item.partnerCode, 'formData')
      state.formLoading = false
      // formData
    }
    function deletePartner (item) {
      console.log(item.dataRef)
      root.$confirm({
        title: '删除提示',
        content: `确定要删除【${item.partnerName}】？`,
        onOk: async () => {
          const { code, msg } = await marketing.deletePartner({
            code: item.partnerCode,
          })
          if (code === '00000') {
            root.$message.success('删除成功')
            if (item.partnerPcode === '0') {
              state.treeData.splice(
                state.treeData.findIndex((x) => x.partnerCode === item.partnerCode),
                1
              )
            } else {
              let pindex = state.treeData.findIndex((x) => x.partnerCode === item.partnerPcode)
              console.log(pindex, state.treeData)
              state.treeData[pindex].children.splice(
                state.treeData[pindex].children.findIndex(
                  (x) => x.partnerCode === item.partnerCode
                ),
                1
              )
              if (state.treeData[pindex].children.length === 0) {
                state.treeData[pindex].isLeaf = true
              }
            }
            state.treeData = [...state.treeData]
            // 删除的是当前选中的
            if (state.currentSelectData.partnerCode === item.partnerCode) {
              if (state.treeData.length && state.treeData[0].partnerCode) {
                state.currentSelectData.partnerCode = state.treeData[0].partnerCode
                getPartnerDetail()
              } else {
                state.currentSelectData = {}
              }
            }
          } else {
            root.$message.error(msg || '删除失败')
          }
        },
      })
    }
    async function expandTree (keys, e) {
      state.expandedKeys = keys
      if (e.node.dataRef.children) return
      const { data, msg, code } = await marketing.getPartnerTreeData({
        code: e.node.dataRef.partnerCode,
      })
      if (code === '00000') {
        e.node.dataRef.children = data.map((x) => {
          return {
            isLeaf: !x.nextFlag,
            ...x,
            scopedSlots: {
              title: 'custom',
            },
          }
        })
        state.treeData = [...state.treeData]
      }
    }
    return {
      options,
      ...toRefs(state),
      getPartnerTreeData,
      regionChange,
      addPartner,
      submit,
      onCopySuccess,
      selectTree,
      onCopyError,
      getQrcode,
      editPartner,
      deletePartner,
      expandTree,
      getVirtualSharerId
    }
  },
}
</script>

<style lang="less" scoped>
.page {
  height: calc(100vh - 136px);
  .content {
    height: 100%;
    display: flex;
    .tree-box {
      height: 100%;
      width: 300px;
      background: #fff;
      box-shadow: 0 8px 12px #ddd;
      flex-shrink: 0;
      margin-right: 16px;
      .tree-title-box {
        display: flex;
        padding: 8px 16px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        span {
          font-size: 16px;
          font-weight: bold;
          margin-right: 24px;
        }
      }
      .tree {
        padding: 0 16px;
      }
    }
    .content-box {
      height: 100%;
      background: #fff;
      box-shadow: 0 8px 12px #ddd;
      flex: 1;
      box-sizing: border-box;
      padding: 8px 16px;
      .content-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 16px;
        line-height: 32px;
      }
      .amount-box {
        display: flex;
        border-bottom: 1px solid #eee;
        margin-bottom: 32px;
        .amount-box-item {
          padding: 0 64px 8px 24px;
          .label {
            font-size: 12px;
            color: #666;
          }
          .value {
            font-size: 20px;
            font-weight: blod;
          }
        }
      }
      .line {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        color: #666;
        padding-left: 32px;
        .label {
          width: 5em;
          text-align: right;
        }
      }
    }
  }
}
.modal-form {
  ::v-deep .ant-form-item {
    margin-bottom: 4px;
  }
}
.qrcode-img {
  width: 100%;
  ::v-deep img  {
    width: 100%;
    object-fit: contain;
  }
}
</style>
